.container {
    height: 100vh;
    width: 100%;
    background-image: url("../../assets/illustrations/illustration_dashboard.png");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    .bg-image {
        width: 100%;
        height: 50vh;
        background-image: url("../../assets/illustrations/dashboard.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .platforms {
        width: 100%;
        height: 20px;
        background-image: url("../../assets/illustrations/platforms.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        bottom: 40px;
    }

    .form-section {
        max-width: 500px;
        width: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(0px 4px 29.2px rgba(0, 0, 0, 0.11));
        background: #fff;
        margin-right: 90px;
        padding: 40px 0;
        position: relative;
        z-index: 99;

        form {
            text-align: start;
            width: 300px;
        }
    }

    .form-container {
        .title {
            color: #000;
            font-size: 32px;
            font-weight: 600;
            line-height: 128.6%;
            margin: 30px 0;
            text-align: center;
        }

        .icon {
            text-align: center;
        }

        button {
            height: 40px !important;
            width: 100%;
            color: #FFFFFF !important;
            border-radius: 4px;
            background: #FDDB19 !important;
            font-size: 16px;
            font-weight: 700;
            max-width: 200px;
            display: block;
            margin: 10px auto 0;
            border-color: #FDDB19 !important;

            &:hover {
                color: #ffffff !important;
                border-color: transparent !important;
                background-color: #FDDB19 !important;
            }

            &:disabled {
                background-color: #B0B0B0 !important;
                border-color: transparent !important;
                color: #ffffff !important;
            }
        }

    }

    .social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .telegram {

            svg {
                width: 24px;
                height: 24px;
            }
        }

        a {
            color: #000;
            display: flex;
            align-items: center;
            gap: 5px;
            text-decoration: none;
            font-weight: 600;
        }
    }

    // .social-buttons {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     gap: 11px;
    //     color: #000;
    //     font-size: 12px;
    //     font-weight: 500;
    //     margin: 0 auto;
    //     width: 100%;


    //     .telegram {

    //         svg {
    //             width: 23px;
    //             height: 23px;
    //         }
    //     }

    //     a {
    //         color: #000;
    //         display: flex;
    //         align-items: center;
    //         gap: 5px;
    //         text-decoration: none;
    //         font-weight: 600;
    //     }
    // }
}

@media screen and (max-width:1190px) {
    .container {
        background-position: 10% 23%;
    }
}

@media screen and (max-width:1290px) {
    .container {
        .form-section {
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: 1117px) {
    .container {
        justify-content: center;
        background-image: url("../../assets/illustrations/mobile.png");
        padding-right: 0;

        .form-section {
            margin: 10px;
        }

        .bg-image,
        .platforms {
            display: none;
        }
    }
}