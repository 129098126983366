.version {
    color: #fff;
    margin-top: 13px;
    text-align: center;
}

.logout-button {
    color: #fff;
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;

    .logout-icon {
        svg {
            width: 20px;
            height: 20px;
            font-weight: 700;
        }
    }
}

.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}