.message-open {
  overflow: hidden;
  height: 25px;
  margin-bottom: 10px;
  transition: height 0.2s;
  text-align: center;
}

.message-close {
  transition: height 0.2s;
  overflow: hidden;
  height: 0;
}

.success-text {
  color: green;
}

.error-text {
  color: red;
}