* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.info-icon {
  color: blue;
  font-size: 18px;
  cursor: pointer;
}

.form-btn-container {
  margin: auto;
  width: max-content;
}

.drawer-title {
  font-size: 20px;
  font-weight: 600;
  color: grey;
}

@media screen and (max-width: 900px) {
  .ant-picker-panels {
    flex-direction: column !important;
  }

  .ant-layout-sider {
    max-width: 300px !important;
    width: 300px !important;
  }
}

.apexcharts-menu-icon {
  display: none;
}

.ant-layout-sider {
  background-image: url("./assets/illustrations/sidebar.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.demo-logo {
  height: 38px;
  margin: 23px auto;
  cursor: pointer;
}

.ant-menu-item {
  color: #FFF !important;
  font-size: 16px;
  font-weight: 600;
}